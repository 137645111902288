<template>
    <div>
        <div class="form-group">
            <label v-if="showLabel" class="form-group__label">{{ placeholder }}</label>
            <vue-tel-input class="form-group__input" v-model="val" @input="input" @validate="validate" :inputOptions="options"></vue-tel-input>
            <span v-if="error" class="form-group__error">{{ Array.isArray(error) ? error[0] : error }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "InputComponent",
    props: {
        name: {
            type: String,
            required: false
        },
        value: {
            required: false
        },
        placeholder: {
            type: String,
            required: false
        },
        showLabel: {
            type: Boolean,
            default: false
        },
        error: {
            required: false,
            default: false
        }
    },
    data() {
        return {
            val: this.value,
            options: {
                enabledCountryCode: false,
                mode: "international",
                placeholder: ''
            }
        }
    },
    methods: {
        input(number, phoneObject) {
            this.$emit('input', number, phoneObject)
        },
        validate(phoneObject) {
            this.$emit('validatePhone', phoneObject)
        },
    }
};
</script>

<style lang="scss">
    .vti__dropdown {
        padding: 12px 15px;

        &:hover {
            background-color: $color_light;
        }
    }
</style>

<style lang="scss" scoped>

.form-group {
    position: relative;

    &__label {
        margin-bottom: 8px;
        color: $color_gray;
    }

    &__input {
        border: solid 1px #EEF3FD;
        border-radius: 2px;
        color: $color_black;
        background-color: $color_light;
        padding: 0;
        font-size: 15px;
        font-family: $ff-primary;
        width: 100%;
        box-sizing: border-box;
        &:focus-within{
            box-shadow: none;
            border: solid 1px $color_lightgray;

        }


        &::placeholder {
            color: $color_black;
        }

        &.is-invalid {
            border-color: $color_red;
        }

        &[disabled] {
            color: $color_gray;
            cursor: initial;
            border: solid 1px $color_light;

            &::placeholder {
                color: $color_gray;
            }
        }
    }

    &__error {
        font-size: 13px;
        color: #FF496D;
        position: absolute;
        right: 0;
        bottom: auto;
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    input[type='password']{
        &:before{
            content: ""
        }
    }
}

::v-deep{
    .vti__dropdown-list.below{
        top: 42px;
    }
    .vti__input{
        background-color: #F8FAFF;
    }
}

</style>
