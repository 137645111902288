<template>
    <div class="users">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <breads :links="breadLinks"/>
                    <PageHeader :title="$t('Users')" :button-caption="$t('Add user')" @pageHeaderButtonClick="addUser"/>
                    <div class="page-content">
                        <TableMain
                            :items="users"
                            :fields="fields"
                            @update="updateUser"
                            @getItems="getUsers"
                            :hideActions="false"
                            :header-columns="columns"
                            :valuesFromRoute="true"
                            :itemsMeta="usersMeta"
                            :preloader="preloader"
                        />
                    </div>
                    <ModalComponent :show="showModal.form" @close="showModal.form = false;" :ask-before-leave="askBeforeLeave" :ask-leave-action="askLeaveAction">
                        <div class="add-user">
                            <h2 class="typography typography--h2 add-user__title">{{ isEditForm ? $t('Edit user') : $t('Add user') }}</h2>
                            <FormComponent
                                @close="$emit('close')"
                                :model="userForm"
                                :noRedirect="true"
                                :method="isEditForm ? 'put' : 'post'"
                                :error="errorHandler"
                                @success="success" :action="newAddUserAction"
                                @isFormDisable="isFormDisable"
                            >
                                <div class="row align-items">
                                    <div class="col-md-6 col-12">
                                        <div class="mb-3">
                                            <InputComponent
                                                name="login"
                                                v-model="userForm.login"
                                                :error="errors.login"
                                                :showLabel="true"
                                                :placeholder="$t('login')"
                                                :required="true"
                                            />
                                        </div>
                                        <div class="mb-3">
                                            <InputComponent
                                                :type="isEditForm ? 'password' : 'text'"
                                                name="password"
                                                v-model="userForm.password"
                                                :error="errors.password_fail"
                                                :showLabel="true"
                                                :placeholder="$t('Password')"
                                                :required="true"
                                            />
                                        </div>
                                        <div class="mb-3">
                                            <InputComponent
                                                name="email"
                                                v-model="userForm.email"
                                                :error="errors.email"
                                                :showLabel="true"
                                                :placeholder="$t('Email')"
                                                :required="true"
                                            />
                                        </div>
                                        <div class="mb-3">
                                            <SelectComponent
                                                v-if="['NetworkAdministrator', 'CinemaEmployee'].includes(roleAlias)"
                                                @input="networksChange"
                                                :lightblue="true"
                                                :options="networks"
                                                :error="errors.network_id"
                                                :show-label="true"
                                                label-key="name"
                                                :placeholder="$t('Network of cinemas')"
                                                :value="userForm.network_id"
                                                name="network_id"
                                                :required="true"
                                            />
                                        </div>
                                        <div class="mb-3">
                                            <SelectComponent
                                                v-if="roleAlias === 'Distributor'"
                                                @input="distributorChange"
                                                :lightblue="true"
                                                :options="distributors"
                                                :error="errors.distributor_id"
                                                :show-label="true"
                                                label-key="name"
                                                :placeholder="$t('Distributors')"
                                                :value="userForm.distributor_id"
                                                name="distributor_id"
                                                :required="true"
                                            />
                                        </div>
                                        <CheckboxComponent
                                            :value="userForm.is_active"
                                            :label="$t('Is active')"
                                            @change="isUserActiveChange"
                                            name="isUserActive"
                                            :required="true"
                                        />
                                    </div>
                                    <div class="col-md-6 col-12 mt-md-0 mt-3">
                                        <div class="mb-3">
                                            <InputPhoneComponent
                                                v-model="userForm.phone"
                                                @input="changePhone"
                                                @validatePhone="validatePhone"
                                                :error="errors.phone"
                                                :showLabel="true"
                                                :placeholder="$t('Phone')"
                                            />
                                        </div>
                                        <div class="mb-3">
                                            <InputComponent
                                                name="name"
                                                v-model="userForm.first_name"
                                                :error="errors.first_name"
                                                :showLabel="true"
                                                :placeholder="$t('Name')"
                                                :required="true"
                                            />
                                        </div>
                                        <div class="mb-3">
                                            <SelectComponent
                                                :lightblue="true"
                                                :options="roles"
                                                :error="errors.role_id"
                                                :show-label="true"
                                                v-model="userForm.role_id"
                                                label-key="name"
                                                :placeholder="$t('Roles')"
                                                name="role_id"
                                                :required="true"
                                            />
                                        </div>
                                        <div class="mb-3">
                                            <MultiselectComponent
                                                v-if="roleAlias === 'CinemaEmployee'"
                                                ref="cinemas"
                                                :value="userForm.cinemas"
                                                @change="multiselectChange"
                                                :options="cinemas"
                                                :error="errors.cinemas"
                                                :show-label="true"
                                                :placeholder="$t('Click on the box to select cinemas')"
                                                :label="$t('Cinema')"
                                                name="cinemas"
                                                :required="true"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="add-user__buttons">
                                            <ButtonComponent
                                                :disabled="isButtonDisabled"
                                                type="submit"
                                                class="me-0 me-sm-4 mb-3 mb-sm-0"
                                                theme="primary"
                                                :caption="isEditForm ? $t('Save') : $t('Add')"
                                            />
                                            <ButtonComponent type="button" theme="bordered" @click="cancelAddNew" :caption="$t('Cancel')" />
                                        </div>
                                    </div>
                                </div>
                            </FormComponent>
                        </div>
                    </ModalComponent>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ButtonComponent from "@/components/ui/ButtonComponent"
    import ModalComponent from "@/components/ui/ModalComponent"
    import FormComponent from "@/components/ui/forms/FormComponent";
    import SelectComponent from "@/components/ui/forms/SelectComponent"
    import MultiselectComponent from "@/components/ui/forms/MultiselectComponent"
    import CheckboxComponent from "@/components/ui/forms/CheckboxComponent";
    import InputComponent from "@/components/ui/forms/InputComponent"
    import InputPhoneComponent from "@/components/ui/forms/InputPhoneComponent"
    import PageHeader from "@/components/PageHeader";
    import Breads from "@/components/Breads";
    import TableMain from "@/components/ui/tables/TableMain"

    export default {
        components: { ButtonComponent, TableMain, PageHeader, Breads, ModalComponent, FormComponent, InputComponent, CheckboxComponent, SelectComponent, MultiselectComponent, InputPhoneComponent },
        data() {
            return {
                columns: [
                    {
                        id: 1,
                        label: this.$t('ID'),
                        sort: false,
                        attribute: "id",
                    },
                    {
                        id: 2,
                        label: this.$t('Email'),
                        sort: false,
                        attribute: "email",
                    },
                    {
                        id: 3,
                        label: this.$t('Name '),
                        sort: false,
                        attribute: "first_name",
                    },
                    {
                        id: 4,
                        label: this.$t('Role'),
                        sort: false,
                        attribute: "roleName",
                    },
                ],
                users: [],
                preloader: true,
                distributors: [],
                rolesList: [
                    {id: 1, name: 'Адміністратор'},
                    {id: 2, name: 'Державний посадовець'},
                    {id: 3, name: 'Дистриб\'ютор'},
                    {id: 4, name: 'Адміністратор мережі'},
                    {id: 5, name: 'Працівник кінотеатру'},
                ],
                fields: [
                    {
                        type: "search",
                        by: "id",
                    },
                    {
                        type: "search",
                        by: "email",
                    },
                    {
                        type: "search",
                        by: "first_name",
                    },
                    {
                        type: "search",
                        by: "id",
                    },
                ],
                isUsersLoaded: false,
                breadLinks: [
                    {
                        label: this.$t('Users'),
                        to: { name: 'AdminUsers' }
                    }
                ],
                showModal: {
                    form: false,
                },
                askLeaveAction: {},
                isEditForm: false,
                userForm: {
                    id: '',
                    login: '',
                    password: '',
                    email: '',
                    first_name: '',
                    is_active: false,
                    phone: '',
                    role: '',
                    network_id: '',
                    cinemas: [],
                    distributor_id: '',
                    role_id: null,
                },
                errors: {
                    login: '',
                    password: '',
                    email: '',
                    first_name: '',
                    is_active: '',
                    phone: '',
                    role: '',
                    network_id: '',
                    cinemas: [],
                    password_fail: '',
                    distributor_id: '',
                    role_id: '',
                },
                roles: [],
                networks: [],
                cinemas: [],
                isCinemasLoaded: false,
                isNetworksLoaded: false,
                isPhoneDirty: false,
                isButtonDisabled: false,
                usersMeta: [],
                userId: '',
                askBeforeLeave: false,
            }
        },
        computed: {
            roleAlias() {
                let checkedRole = this.roles.find(role => role.id === this.userForm.role_id);
                return checkedRole ? checkedRole.alias : '';
            },
            // askBeforeLeave() {
            //     let res = Object.keys(this.userForm).filter(elem => {
            //         if (this.userForm[elem] && this.userForm[elem].length > 0) {
            //             return this.userForm[elem].length > 0
            //         }
            //     });
            //     return !!res.length
            // },
            newAddUserAction() {
                return this.isEditForm ? `/api/v1/users/${this.userId}` : `/api/v1/users`
            }
        },
        watch:{
            userForm:{
                handler: function(){
                    this.askBeforeLeave = true
                },
                deep: true
            }
        },

        methods: {
            normalizeData(arr) {
                return arr.map(item => Object.assign(item, {
                    label: item.name
                }));
            },
            multiselectChange(v) {
                this.userForm.cinemas = [];
                v.map(elem => {
                    this.userForm.cinemas.push(elem.id)
                })
            },
            isFormDisable(v) {
                this.isButtonDisabled = v
            },
            changePhone(v) {
                if (this.isPhoneDirty == false && v.length && this.userForm.phone.length >=10) {
                    this.isPhoneDirty = !this.isPhoneDirty
                    this.errors.phone = this.$t('Phone Error')
                }
            },
            validatePhone(phoneObject) {
              if (phoneObject.valid === true) {
                    this.userForm.phone = phoneObject.number;
                    this.errors.phone = ''
              }
              else if (phoneObject.valid === false && this.userForm.phone.length >= 10) {
                this.userForm.phone = '';
                this.errors.phone = this.$t('Phone Error')
              }
            },
            networksChange(v) {
                this.userForm.network_id = v;
                this.getCinemas({ no_pagination: true, networks_id: v})
            },
            distributorChange(v) {
                this.userForm.distributor_id = v;
            },
            roleChange(v){
                this.userForm.role_id = v
            },
            getRolesList(){
                this.api.user.getRoles().then(roles => {
                    this.roles = roles.data;
                });
            },
            async getDistributorList(){
                let resp = await this.api.distributor.getDists({ no_pagination: true })
                this.distributors = this.normalizeData(resp.data);
            },
            addUser() {
                this.clearErrorsAndValues();
                this.getCinemas({ no_pagination: true })
                this.isEditForm = false;
                this.showModal.form = true;
                setTimeout(() => this.askBeforeLeave = false, 500)
            },
            async updateUser(id) {
                await this.getCinemas({ no_pagination: true })
                this.clearErrorsAndValues()
                let user = this.users.find(user => user.id === id);

                if(user) {
                    this.userForm = Object.assign(user, {
                        password: '',
                    });
                }

                this.isEditForm = true;
                this.showModal.form = true
                this.userId = id;
                setTimeout(() => this.askBeforeLeave = false, 500)
            },
            clearErrorsAndValues() {
                this.userForm = {
                    login: '',
                    password: '',
                    email: '',
                    id: '',
                    first_name: '',
                    is_active: false,
                    phone: '',
                    role: '',
                    network_id: '',
                    cinemas: []
                };

                this.errors = {
                    login: '',
                    password: '',
                    email: '',
                    first_name: '',
                    is_active: '',
                    phone: '',
                    role: '',
                    network_id: '',
                    cinemas: []
                },

                this.isButtonDisabled = false
            },
            async getUsers(query = null) {
                if (!query) {
                    if (!Object.keys(this.$route.query).length) {
                        this.$router.push({ query: {page: 1, sort_by: 'id'} });
                    }
                    query = this.$route.query
                }
                this.isUsersLoaded = false
                let users = await this.ApiService.user.getUsers(query)
                this.usersMeta = users.meta
                this.users = users.data
                users.data.forEach(user => {
                    let roleName = this.rolesList.find(role => role.id === user.role_id)
                    if (roleName){
                        user.roleName = roleName.name
                    }
                })
                this.$nextTick(() => {
                    this.isUsersLoaded = true
                })
                this.preloader = false
            },
            async getCinemas(params = null) {
                this.isCinemasLoaded = false
                let cinemas = await this.ApiService.cinema.getCinemas(params)

                this.cinemas = this.normalizeData(cinemas.data);
                this.isCinemasLoaded = true
            },
            async getNetworks(params = null) {
                let networks = await this.ApiService.network.getNetworks(params)
                this.isNetworksLoaded = true
                networks.data.map(elem => {
                    elem.label = elem.name
                    return elem
                })
                this.networks = networks.data
            },
            cancelAddNew() {
                let res = Object.keys(this.userForm).filter(elem => {
                    if (this.userForm[elem] && this.userForm[elem].length > 0) {
                        return this.userForm[elem].length > 0
                    }
                });
                this.askLeaveAction = { value: !!res.length }
            },
            success(type) {
                let successMessages = {
                    create: 'User added',
                    update: 'Information successfully updated'
                }
                this.toast.success(this.$t(successMessages[type]));

                this.showModal.form = false;
                this.getUsers();
            },
            errorHandler(errors) {
                if (this.errors.phone?.length) {
                    this.errors = errors;
                    this.errors.phone = this.$t('Phone Error')
                    return 1
                } else {
                    this.errors = errors;
                }

            },
            isUserActiveChange(v) {
                this.userForm.is_active = v;
            },
        },

        mounted() {
            this.getUsers()
            this.getCinemas({ no_pagination: true, network_id: 'null' })
            this.getNetworks({ no_pagination: true })
        },
        created() {
            this.getDistributorList()
            this.getRolesList()
        }
    }
</script>

<style lang="scss">
    .add-user {
        width: 950px;
        max-width: 100%;

        &__title {
            text-align: center;
            margin-bottom: 30px;
        }

        &__buttons {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 50px;

            @media screen and (max-width: $sm) {
                flex-direction: column;
            }
        }
        .multiselect__tags{
            min-height: 43px;
        }
    }

</style>
