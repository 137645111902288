<template>
    <div class="select-element">
        <span v-if="showLabel" class="select-element__label">{{ label }}</span>
        <a @click="multiselectCancel" class="select-element__cancelAll">{{ $t('Delete all') }}</a>
        <multiselect
            v-model="val"
            :options="options"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :placeholder="placeholder"
            label="label"
            track-by="id"
            :taggable="false"
            selectLabel=""
            selectedLabel=""
            deselectLabel=""
            :disabled="disabled"
            @input="$emit('change', val)"
            :class="[multiselectClass ? multiselectClass : '']"
        >
        <span slot="noOptions">{{ $t('No elements found') }}</span>
        <span slot="noResult">{{ $t('No elements found') }}</span>
        </multiselect>
        <span v-if="error.length" class="select-element__error">{{ Array.isArray(error) ? error[0] : error }}</span>
    </div>
</template>

<script>
    export default {
        name: "MultiselectComponents",
        props: {
            showLabel: {
                type: Boolean,
                default: false,
            },
            label: {
                required: false,
                type: String,
            },
            value: {
                required: false,
            },
            multiple: {
                type: Boolean,
                default: false,
            },
            name: {
                type: String,
                required: true,
            },
            options: {
                required: true,
                type: Array,
            },
            placeholder: {
                required: false,
                type: String,
            },
            clearable: {
                required: false,
                type: Boolean,
                default: false,
            },
            multiselectClass: {
                type: String,
                default: ''
            },
            error: {
                required: false,
                default: "",
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                val: this.value
            }
        },
        methods: {
            close() {
                this.$emit("close")
            },
            multiselectCancel() {
                this.val = [];
                this.$emit('change', this.val)
            }
        },
        mounted() {
            let selectedList = this.options.filter((n) => {
                return this.val.includes(n.id)
            });

            this.val = selectedList.map(e => {
                return {
                    id: e.id,
                    label: e.label
                }
            })
        }
    }
</script>

<style lang="scss" scoped>
    .multiselect {
        //border: solid 1px #CAD9F3;
        //border-radius: 2px;
        //color: #141213;
        //background-color: white;
        //font-size: 15px;
        //font-family: "VW";
        //width: 100%;
        //box-sizing: border-box;
    }

    .select-element {
        position: relative;

        &__label {
            margin-bottom: 8px;
            color: $color_gray;
            display: block
        }

        &__cancelAll {
            cursor: pointer;
            position: absolute;
            right: 0;
            top: 0;
            color: $color_blue;
            font-weight: 600;
        }

        &__error {
            //font-size: 13px;
            //color: $color_red;
            //position: absolute;
            //right: 0;
            //bottom: -18px;
            font-size: 13px;
            color: #FF496D;
            position: absolute;
            right: 0;
            bottom: auto;
            max-width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
</style>

<style lang="scss">

    .multiselect {
        border: solid 1px #EEF3FD;
        border-radius: 2px;
        color: #141213;
        background-color: white;
        font-size: 15px;
        width: 100%;
        box-sizing: border-box;

        &__tag {
            margin: 0 5px 0 0;
            padding: 9px 30px 8px 5px;
            border-radius: 3px;
            color: $color_black;
            font-size: 18px;
            background-color: #edf3ff;
        }

        &__select::before {
            display: none;
        }

        &__tag-icon {
            background: $color_black;
            border-radius: 50%;
            height: 15px;
            width: 15px;
            position: absolute;
            padding: 1px 4px;
            padding-bottom: 4px;
            top: 10px;
            font-weight: inherit;
            margin-right: 7px;

            &:hover, &:focus {
                background: $color_blue
            }

            &::after {
                font-size: 13px;
                color: white;
                position: relative;
                top: -7px;
                left: -1px;
            }
        }

        &__placeholder {
            padding-top: 8px;
            padding-left: 12px;
            font-size: 15px;
            color: $color_gray;
        }

        &__option--highlight {
            background: $color_blue
        }

        &__option--selected.multiselect__option--highlight {
            background-color: $color_black;
        }

        &__tags {
            min-height: 90px;
            display: block;
            padding: 3px 40px 0 4px;
            border-radius: 5px;
            border: none;
            background-color: rgba(237, 243, 255, 0.4);
            font-size: 14px;
            cursor: pointer;
        }

        &__input {
            padding-top: 8px;
            padding-left: 12px;
        }

        &--mini {
            .multiselect__tags {
                min-height: 40px;
            }
        }
    }

    .multiselect--disabled {
        .multiselect__tags {
            background-color: #f4f4f4;
        }

    }

</style>
