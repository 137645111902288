<template>
    <div class="checkbox form-check">
        <label class="form-check-label" :for="isUserActive">
            <input class="form-check-input" v-model="val" @change="$emit('change', $event.target.checked)" type="checkbox" :id="isUserActive" />{{ label }}
        </label>
    </div>
</template>

<script>
    export default {
        props: {
            isUserActive: {
                type: String
            },
            label: {
                type: String
            },
            value: {
                required: false
            }
        },

        data() {
            return {
                val: this.value
            }
        }
    }
</script>

<style lang="scss" scoped>
.form-check-input{
    &:focus{
        box-shadow: none;
    }
}
    .form-check-input:checked {
        width: 16px;
        height: 16px;
        border-radius: 4px;
        background-color: $color_lightblue;
        background-image: none;
        border-color: $color_light;
        position: relative;
        top: 1px;
    }

    .form-check-label {
        font-size: 18px;
    }
</style>
