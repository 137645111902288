<template>
    <form class="form" @submit.prevent="submit">
        <slot></slot>
    </form>
</template>

<script>
import axios from 'axios'

export default {
    name: "FormComponent",
    props: {
        action: {
            type: String
        },
        disabledForm: {
            required: false,
            type: Boolean,
            default: false
        },
        method: {
            required: false,
            type: String,
            default: 'post'
        },
        success: {
            required: false,
            type: Function
        },
        error: {
            required: false,
            type: Function
        },
        noRedirect: {
            required: false,
            default: false
        },
        model: {
            required: false,
        },
        removeBeforeSubmit: {
            required: false,
            type: Object,
            default: null
        }
    },
    methods: {
        async submit(e) {
            if (this.disabledForm) {
                return false;
            }

            if (this.error({}) == 1) {
                return false
            }

            if (this.removeBeforeSubmit) {
                if (this.removeBeforeSubmit.type == 'halls') {
                    this.removeBeforeSubmit.removedHalls.map(id => {
                        this.ApiService.cinema.deleteCinemasHall(id)
                    })
                }
            }

            this.$emit('isFormDisable', true)

            //let formData = new FormData(e.target);
            let formData = this.model;

            let headers;

            let resp;
            if (this.method === 'get') {
                let params = {};
                for (let pair in formData.entries()) {
                    params[pair[0]] = pair[1];
                }
                resp = await axios.get(this.action, {params}).catch(this.catchHandler);

            } else if (this.method === 'put') {
                resp =  await this.ApiService.common.customRequest('put', this.action + '?_method=PUT', formData, headers).then(data => {
                    this.$emit('success', 'update')
                }).catch(err => {
                    this.catchHandler(err)
                }).finally(() => {
                    this.$emit('isFormDisable', false)
                })

            } else {
                resp =  await this.ApiService.common.customRequest('post', this.action, formData, headers).then(data => {
                    this.$emit('success', 'create')
                }).catch(err => {
                    console.log(err);
                    this.catchHandler(err)
                }).finally(() => {
                    this.$emit('isFormDisable', false)
                })
            }

            if (!resp) {
                return;
            }

            if (typeof this.success === 'function') {
                this.success(resp);
            } else {
                if (!this.noRedirect) {
                    this.$router.back();
                }
            }
        },
        catchHandler(err) {
            if (err?.statusCode === 422) {
                if (typeof this.error === 'function') {
                    this.error(err.messages);
                }

            } else {
                this.toast.error(this.$t('Server error'))
            }
        }
    }
}
</script>
